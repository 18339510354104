import React, { Component } from 'react';
import debounce from 'lodash/debounce'
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'
import Address from '../../shared/components/form-section/Address';
import Contact from '../../shared/components/form-section/Contact';

import { Form, Input, Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Label } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import {
  renderField,
  renderNumberField,
  renderCheckbox,
  renderMultiselect,
  renderDropdownListselect
} from '../../shared/components/form-field/ReduxFormFields';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'
import DropdownList from 'react-widgets/lib/DropdownList';

import { getBank, addBank, updateBank, resetBank } from '../../actions/bankActions';
import { checkAuth } from '../../actions/sessionActions';
import { getBrands } from '../../actions/brandActions'
import { validate } from './validate';

import './BankForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
import { API_URL, X_API_KEY, X_API_VERSION } from '../../conf';
import { getCookie } from '../../shared/utils/cookies';
import axios from 'axios';
import { axiosInterceptors } from '../../shared/utils/axiosInterceptors';
import BrandLogPopup from '../brand/BrandLogPopup';
import Log from '../brand/Log';
// Configuring Redux Store
const store = configureStore(window.initialState);

const messages = {
  emptyFilterList: 'No results. Try another query'
}

class BankForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      open: false,
      logInit: false,
      create: false
    }
    this.submit = this.submit.bind(this);
    this.handleLog = this.handleLog.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    const { getBank, getBrands } = this.props;
    if (this.state.id) {
      getBank(this.state.id);
    }
    getBrands()
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
    if (!this.props.match.params.id) {
      if (!this.state.create) {
        //this.props.dispatch(reset('bankForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetBank());
        this.setState({ create: true });
      }
    }
  }

  // Save Bank
  submit(values) {
    var action;
    if (typeof (values.active) == 'undefined') {
      values.active = false;
    }

    if(values.regioplus) {
      values.regioplus = values.regioplus.toLowerCase();
    }

    if(values.onlineplus) {
      values.onlineplus = values.onlineplus.toLowerCase();
    }

    if(values.access) {
      values.access = values.access.toLowerCase();
    }

    if (this.state.create) {
      action = addBank(values);
    } else {
      action = updateBank(values);
    }

    store.dispatch(action).then((result) => {
      if (typeof (result) != 'undefined') {
        this.setState({ open: true });
      }
    }).catch((error) => {
      throw new SubmissionError({ _error: error });
    });
  }

  handleClose() {
    this.setState({ open: false });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  handleBrandsSearch = debounce((searchTerms) => {
    this.brandsSearch(searchTerms)
  }, 500)

  brandsSearch(searchTerms) {
    console.log('handleBrandsSearch')
    const params = {}
    const search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim())
    Object.assign(params, search)
    store.dispatch(getBrands(params)).then((result) => {
      const brandsSearchResult = result.payload.items.map(brand => ({
        id: brand.id,
        name: (brand.name?.en || brand.name)
      }));
      this.setState({ brandsSearchResult })
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      })
    })
  }

  onSearchInputChange() {
    console.log(1);
  }

  handleBack(e) {
    e.preventDefault();
    console.log(this.state);
    this.setState(e => ({ ...e, logInit: false }))
  }

  handleLog(e) {
    e.preventDefault();
    let url = API_URL + '/banks/';

    url += `${this.state.id}/events`;
    // Axios Data
    const axiosData = {
      method: 'GET',
      url: url,
      headers: {
        //Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': X_API_KEY,
        'X-Api-Version': X_API_VERSION,
        'Authorization': 'Bearer ' + getCookie("_str")
      }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    // If everything is correct we dispatch our received action
    // otherwise our error action.
    return axiosInstance(axiosData)
      .then(response => {
        const { data } = response;
        this.setState(item => ({ ...item, logList: data, logInit: true }))
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        this.setState(item => ({ ...item, logInit: false }))
        console.log('AXIOS ERROR:', err.response);
        // dispatch(error(GET_LOGS_ERROR));
      });
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  render() {
    console.log('---render bank form')

    const session = this.props.session
    if (typeof (session.forceLogout) != "undefined" && session.forceLogout == 1) {
      this.setState({ session: null });
      window.location.href = "/login";
    }

    const { error, handleSubmit, pristine, reset, submitting, message, brands } = this.props

    const paymentsEnabled = this.props?.paymentsEnabled

    let brandsArr = brands.map(brand => ({ id: brand.id, name: brand.name }))
      .sort(function (a, b) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })

    if (this.state.brandsSearchResult != null) {
      brandsArr = this.state.brandsSearchResult
    }

    return (
      <div className="animated fadeIn mt-2">
        <form onSubmit={handleSubmit(this.submit)}>
          <Row>
            <Col xs="12" lg="12">
              <Card>
                <CardHeader><i className="fa fa-align-justify"></i>
                  <button
                    onClick={this.handleBack}
                    style={{
                      background: '#0000',
                      border: 0,
                      cursor: 'pointer',
                      color: '#040D21',
                      textShadow: !this.state.logInit ? '1px 0 0 currentColor' : null
                    }}>Bank</button>

                  <span style={{
                    margin: '0 5px'
                  }}>
                    /
                  </span>

                  <button
                    onClick={this.handleLog}
                    style={{
                      background: '#0000',
                      border: 0,
                      cursor: 'pointer',
                      color: '#040D21',
                      textShadow: this.state.logInit ? '1px 0 0 currentColor' : null
                    }}>
                    Log
                  </button>
                </CardHeader>
                {
                  !this.state.logInit &&
                  <CardBody>
                    <Row className="form-sections">
                      <Col xs="12" lg="6">
                        <FormSection className="form-section">
                          <Card>
                            <CardBody>
                              <CardTitle>Company</CardTitle>
                              <Field
                                name="name"
                                type="text"
                                component={renderField}
                                label="Name"
                                className="form-control"
                                asterisk="*"
                              />
                              <Field
                                name="identifier"
                                type="text"
                                component={renderField}
                                label="Identifier"
                                className="form-control"
                                asterisk="*"
                              />
                              {/*<Field
                              name="api_key"
                              type="text"
                              component={renderField}
                              label="API Key"
                              className="form-control"
                              disabled={true}
                            />*/}
                              <Field
                                name="url"
                                type="text"
                                component={renderField}
                                label="URL"
                                className="form-control"
                              />
                              <br />
                              <Field name="active" component={renderCheckbox} color="primary" label="Active" />
                              {/*<Field name="payments_enabled" component={renderCheckbox} color="primary" label="Payments enabled" />*/}
                              {/*{paymentsEnabled &&*/}
                              {/*<Field name="payments_linked" component={renderCheckbox} color="primary" label="Payments linked"*/}
                              {/*       disabled={true}*/}
                              {/*/>*/}
                              {/*}*/}
                            </CardBody>
                          </Card>
                        </FormSection>
                      </Col>

                      <Col xs="12" lg="6">
                        <Card>
                          <CardBody>
                            <CardTitle className="d-inline">
                              Settings
                            </CardTitle>
                            {
                              [
                                'meinplus',
                                'withdrawals',
                                'payments_linked',
                                'payments_enabled',
                                'hide_selection'
                              ].map(item => (
                                <Field key={item} name={item} value={true} component={renderCheckbox} color="primary" label={(item.charAt(0).toUpperCase() + item.slice(1)).replace(/_/g, " ")} />
                              ))
                            }

                            <div className='mb-3'>
                              <label className="d-block mt-2">
                                Regio plus
                              </label>

                              <Field
                                  name="regioplus"
                                  data={['Enabled', 'Disabled', 'Limited']}
                                  placeholder="Choose Regio plus"
                                  className="status-dropdown  mb-3 d-block"
                                  component={renderDropdownListselect}
                                />

                              <label className="d-block mt-2">
                                Online plus
                              </label>

                              <Field
                                  name="onlineplus"
                                  data={['Enabled', 'Disabled', 'Limited']}
                                  placeholder="Choose Online plus"
                                  className="status-dropdown  mb-3 d-block"
                                  component={renderDropdownListselect}
                                />


                              <label className="d-block mt-2">
                                Access
                              </label>

                              <Field
                                  name="access"
                                  data={['Enabled', 'Disabled', 'Limited']}
                                  placeholder="Choose Access"
                                  className="status-dropdown  mb-3 d-block"
                                  component={renderDropdownListselect}
                                />

                            </div>

                            <Field
                              name="skipped_brands"
                              component={renderMultiselect}
                              data={brandsArr}
                              value={[]}
                              valueField="id"
                              textField="name"
                              filter={false}
                              onSearch={this.handleBrandsSearch}
                              messages={messages}
                            />
                            <Label className="mt-1">Type to search Brand</Label>

                            <Field name={'min_withdraw_limit'} component={renderNumberField} className={'form-control'} step={'0.01'} color="primary" label={'Min withdraw limit'} />

                            {/*<Field key={'hide_selection'} name={'hide_selection'} value={true} component={renderCheckbox} color="primary" label={('hide_selection'.charAt(0).toUpperCase() + 'hide_selection'.slice(1)).replace(/_/g, " ")} />*/}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-3 form-sections">
                      <Col xs="12" lg="6">
                        <FormSection name="address">
                          <Address />
                        </FormSection>
                      </Col>
                      <Col xs="12" lg="6">
                        <FormSection name="contact"><Contact /></FormSection>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                        <button type="submit" className="btn btn-primary mt-2">Submit</button>
                      </Col>
                    </Row>
                  </CardBody>
                }

                {
                  this.state.logInit &&
                  <Log data={this.state.logList} />
                }
              </Card>
            </Col>
          </Row>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={1000} message="Saved"
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

BankForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  bank: PropTypes.object,
  initialValues: PropTypes.object
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);

  // if start create new bank
  if (!state?.bankForm?.bank && state?.form?.bankForm && !state?.form?.bankForm?.values) {
    state.form.bankForm.values = { payments_enabled: true }
  }

  if (state?.form?.bankForm?.values) {
    state.form.bankForm.values.payments_linked = state.form.bankForm.values.payments_enabled && state.form.bankForm.values.payments_linked
  }
  return {
    //formData: state.banks,
    paymentsEnabled: typeof (state.form?.bankForm?.values) !== 'undefined' ? state.form.bankForm.values.payments_enabled : false,
    initialValues: typeof (state.bankForm) !== 'undefined' ? state.bankForm.bank : null,
    bank: typeof (state.bankForm) !== 'undefined' ? state.bankForm.bank : null,
    brands: state?.brands?.result?.items || [],
    session: state.session
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getBank, addBank, updateBank, resetBank, checkAuth, getBrands }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
  form: 'bankForm', // a unique identifier for this form,
  enableReinitialize: true,
  validate
})(BankForm))